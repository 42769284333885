

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Sen:wght@400..800&display=swap');



body::-webkit-scrollbar {
    display: none;
  }